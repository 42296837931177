import React, { useEffect, useState } from "react";
import Participant from "./Participant";

const Room = ({ roomName, room, handleLogout }) => {
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };

    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    room.participants.forEach(participantConnected);
    return () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
    };
  }, [room]);

  const remoteParticipants = participants.map((participant) => (
    <Participant key={participant.sid} participant={participant} />
  ));

  return (
    <>
      <div style={{ display:'flex', margin: "5px" }}>
        {/* <h4>Room: {roomName}</h4> */}
        <h4 className="vd_text">Video Consultation</h4>
        <button className="logout" onClick={handleLogout}>
          Discontinue
        </button>
      </div>
      <div className="room">
        {/* <button
          style={{ float: "right", marginTop:'6px' }}
          className="logout"
          onClick={handleLogout}
        >
          Log out
        </button> */}
        <div className="remote-participants">
          {remoteParticipants}
          <div className="local-participant hostuser">
            {room ? (
              <Participant
                key={room.localParticipant.sid}
                participant={room.localParticipant}
              />
            ) : (
              ""
            )}
          </div>
        </div>

        {/* <h3>Remote Participants</h3> */}
      </div>
    </>
  );
};

export default Room;
